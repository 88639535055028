import {Modal, Collapse} from "bootstrap";
import Splide from '@splidejs/splide';
import recaptcha from "./modules/recaptcha";
import Inputmask from "inputmask/lib/inputmask";
import lazyImg from "./modules/lazyImg";
import favorite from "./modules/favorite";
import liveSearch from "./modules/liveSearch";
import 'jquery-validation'
import ru from 'jquery-validation/dist/localization/messages_ru'

$(document).ready(() => {
    recaptcha()
    lazyImg()
    favorite()
    liveSearch()

    $('form#login-form').validate({
        lang: ru,
        errorClass: 'is-invalid',
        errorElement: 'small',
        validClass: 'is-valid',
        rules: {
            email: {
                required: true,
                email: true
            },
            password: {
                required: true,
            }
        }
    })

    Inputmask('999999', {
        clearIncomplete: true,
        showMaskOnHover: false,
    }).mask('input[name="code"]')

    if($('.splide--banners').length) {
        new Splide('.splide--banners', {
            perPage: 1,
            perMove: 1,
            type: 'loop',
            autoplay: true,
            pagination: false
        }).mount();
    }

    if($('.splide--product-images').length) {
        new Splide('.splide--product-images', {
            perPage: 1,
            perMove: 1,
            pagination: false
        }).mount();
    }

    // Splide Product kits
    let kits = $('.splide--product-kit');

    for (let i = 0; i < kits.length; i++) {
        new Splide(kits[i], {
            perPage: 4,
            rewind: true,
            gap: 15,
            pagination: false,
            breakpoints: {
                767: {
                    perPage: 2,
                },
                1199: {
                    perPage: 3,
                }
            }
        }).mount();
    }
})
