import notif from "./notif";

const favorite = () => $('[data-favorite-btn]').click(function (e) {
    let product = $(this)
    let product_id = product.data('product-id')
    let type = product.attr('data-type')
    let route = product.data('route')

    switch (type) {
        case 'POST':
            axios.post(route, {
                product_id: product_id
            }).then(res => {
                notif.success('Товар добавлен в избранное');
                product.find('i').removeClass('bi-star text-dark').addClass('bi-star-fill text-primary')
                product.attr('data-type', 'DELETE')
            }).catch(err => {
                if (err.request.status) {
                    notif.error(err.request.responseText);
                } else {
                    console.log(err)
                }
            })
            break
        case 'DELETE':
            axios.delete(route, {
                data: {
                    product_id: product_id
                }
            }).then(res => {
                notif.success('Товар удалён из избранного')
                product.find('i').removeClass('bi-star-fill text-primary').addClass('bi-star text-dark')
                product.attr('data-type', 'POST')
            }).catch(err => {
                console.log(err)
            })
            break
    }
})

export default favorite
